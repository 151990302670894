.app-link-div{
    display: inline;
}

.app-link {
    display: inline;
    cursor: pointer;
}

.app-link img{
    margin: 5px;
    display: inline;
    width: 180px;
    height: 50px;
}

.app-name-img img{
    width: 300px;
    height: 45px;
}



.app-link-div.smallscreen{

    display: none;
}

@media screen and (max-width: 960px) {
    .app-link-div.fullscreen{
        display: none;
    }
    .app-link-div.smallscreen{
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 60px;
        margin-bottom: 20px;
    }
}