.search-bar {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px 15px;
    border: 2px solid dodgerblue;
    border-radius: 25px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(30, 144, 255, 0.6);
    border-color: deepskyblue;
}
