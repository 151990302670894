.offer .offer-preview-container {
    min-height: 100vh;
    text-align: center;
    background-color: lightskyblue;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.5);
}


.offer .offer-img {
    /* height: 75vh; */
    width: 100%;
    object-fit: contain;

}

.offer .offer-img img {
    margin-top: 10px;
    border-radius: 10px;    
    width: 100%;
    object-fit: contain;
    box-shadow: 0 0 4px 0.2px rgba(0,0,0,0.5);

}

.offer .app-name-img img{
    width: 200px;
    height: 30px;
}

.offer .app-link-div img {
    height: 45px;
    width: 150px;
}

.offer .dowload-option-container{
    margin-top: 20px;
}

.offer .link{
    cursor: pointer;
}