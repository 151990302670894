* {
  font-family: 'Afacad', sans-serif;
}

.cards-page-container {
  text-align: center;
  padding: 20px;
}

.header-title {
  font-weight: 600;
  font-size: 40px;
  /* Adjust based on your design */
  color: black;
  /* Use your theme color */
  margin-bottom: 20px;
  /* Space above the search bar */
  font-weight: bold;
  font-family: 'Dancing Script', cursive;
  animation: pulse 1s infinite alternate;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }

  .header-title {
    font-size: 40px
  }
  
}

@media (max-width: 500px) {


  .header-title {
    font-size: 28px
  }
  
}