.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    margin: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.special-offer{
    font-size: 14px;
    /* margin: 0px 5%; */
    /* width: 80%; */
    background-color: #FFD700;
    padding: 10px;
    margin-top: 5px;
    border-Radius: 5px;

}

.modal-input {
    width: 100%;
    /* Adjust the width as needed */
    padding: 10px;
    margin-bottom: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.modal-input:focus {
    border-color: dodgerblue;
    outline: none;
}

.modal-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.modal-button.submit {
    background-color: dodgerblue;
    color: white;
}

.modal-button.submit:hover {
    background-color: darkblue;
}

.modal-button.cancel {
    background-color: lightgray;
    color: black;
}

.modal-button.cancel:hover {
    background-color: gray;
}

.button-row {
    display: flex;
    justify-content: center;
    /* Center the button horizontally */
    margin-top: 10px;
    /* Spacing between the button and other elements */
}