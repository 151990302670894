.contact-info-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.contact-info-page h1 {
  font-size: 32px;
  margin-bottom: 40px;
}

.card {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.contact-info-page h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-info-page p {
  font-size: 16px;
  margin: 10px 0;
}

.contact-info-page a {
  color: #007bff;
  text-decoration: none;
}

.contact-info-page a:hover {
  text-decoration: underline;
}
