.card-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
    padding-bottom: 0px;
    justify-content: flex-start;
    /* Align items to the start (left) */
}

.card-header img.bank-logo {
    width: 50px;
    /* Adjust the size as needed */
    height: auto;
    margin-right: 10px;
}

.card-container img.card-image {
    width: 100%;
    object-fit: cover;
}

.card-container img.bank-logo {
    width: 50px;
    /* Adjust the size as needed */
    height: auto;
    margin: 10px;
}

.card-info {
    padding: 15px;
    padding-top: 10px;
    width: 100%;
    text-align: left;
    /* Align text to the left */
}


.features-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* Allow items to wrap to the next line */
    gap: 10px;
    margin-top: 10px;
}

.feature-item {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    /* Removed white-space: nowrap to allow wrapping */
}

.interest-button {
    width: 100%;
    background-color: dodgerblue;
    /* Dodger blue background */
    color: white;
    /* White text color */
    padding: 5px 15px;
    /* Padding for the button */
    padding-bottom: 8px;
    border: none;
    /* No border */
    border-radius: 4px;
    /* Rounded corners */
    cursor: pointer;
    /* Cursor changes to pointer on hover */
    margin-top: 15px;
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
}

.interest-button:hover {
    background-color: darkblue;
    /* Darker blue when hovered */
}

.min-salary {
    font-weight: normal;
    /* Regular font weight for the label */
    margin-bottom: 5px;
    /* Spacing before the description */
}

.salary-amount {
    font-weight: bold;
    /* Bold font weight for the amount */
    color: dodgerblue;
    /* Dodger blue color for the amount */
}

.card-info p {
    text-align: justify; /* Justify the text content */
    text-justify: inter-word; /* Improve spacing between words */
  }